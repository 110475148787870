const data = [
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/1.png",
    text: "1",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/2.png",
    text: "2",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/3.png",
    text: "3",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/4.png",
    text: "4",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/5.png",
    text: "5",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/6.png",
    text: "6",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/7.png",
    text: "7",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/8.png",
    text: "8",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/9.png",
    text: "9",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/10.png",
    text: "10",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/11.png",
    text: "11",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/12.png",
    text: "12",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/13.png",
    text: "13",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/14.png",
    text: "14",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/15.png",
    text: "15",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/16.png",
    text: "16",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/17.png",
    text: "17",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/18.png",
    text: "18",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects-re/nailritual/19.png",
    text: "19",
  },
];

export default data;
